import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modalHapus: false,
        berita: {
            berita_id: '',
            berita_judul: '',
            berita_slug: '',
            berita_deskripsi: '',
            berita_thumbnail: '',
            berita_thumbnail_ket: '',
            berita_isi: '',
            berita_created_at: ''
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.modalHapus = value
        },
        viewModal(state, value) {
            state.berita = value
        }
    }
})
