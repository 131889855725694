<template>
  <v-dialog
    v-model="ModalAdd"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            color="primary"
            fixed
            bottom
            right
            fab
            v-bind="attrs"
            v-on="{ ...tooltip, ...modal }"
            @click="openModal()"
          >
            <v-icon>mdi-message-text-outline</v-icon>
          </v-btn>
        </template>
        <span>Buat Berita</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>Tambah Berita</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="ModalAdd = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="mx-7 mt-5">
          <v-row>
            <!-- Judul Berita -->
            <v-col cols="12" md="12" class="mb-n8">
              <span class="subtitle-2">Judul Berita</span>
              <v-text-field
                dense
                flat
                outlined
                class="mt-2"
                v-model="berita_judul"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- Thumbnail -->
            <v-col cols="12" md="12" class="mb-n8">
              <span class="subtitle-2">Thumbnail</span>
              <v-file-input
                dense
                flat
                outlined
                prepend-icon
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Pilih Thumbnail"
                append-icon="mdi-image"
                @change="onFile"
                ref="avatar"
              ></v-file-input>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" v-if="!urlImage" class="mb-n5">
              <v-card
                class="d-flex align-center justify-center"
                outlined
                height="400"
              >
                <div>
                  <v-icon>mdi-image</v-icon>
                  <span>Preview Image</span>
                </div>
              </v-card>
            </v-col>

            <v-col cols="12" v-else>
              <v-card
                class="d-flex align-center justify-center"
                outlined
                height="400"
              >
                <div>
                  <v-img :src="urlImage"></v-img>
                </div>
              </v-card>
            </v-col>

            <!-- Deskripsi Thumbnail -->
            <v-col cols="12" md="12" class="mb-n8">
              <span class="subtitle-2">Deskripsi Thumbnail</span>
              <v-text-field
                dense
                flat
                outlined
                class="mt-2"
                v-model="berita_thumbnail_ket"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- Deskripsi Berita -->
            <v-col cols="12" md="12" class="mb-n8">
              <span class="subtitle-2">Deskripsi Berita</span>
              <v-textarea
                dense
                flat
                outlined
                class="mt-2"
                v-model="berita_deskripsi"
                :maxlength="250"
                autocomplete="off"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <!-- Isi Berita -->
            <v-col cols="12" class="mb-3">
              <editor
                ref="toastuiEditor"
                :initialValue="editorText"
                :options="editorOptions"
                height="500px"
                initialEditType="wysiwyg"
                previewStyle="vertical"
                @change="getHTML()"
              />
            </v-col>
          </v-row>

          <hr />

          <div class="text-right mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="add()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getRef.js";

import refreshView from "@/store/informasi/berita/viewBerita";

import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/vue-editor";

export default {
  components: {
    editor: Editor,
  },

  data: () => ({
    session: "",
    ModalAdd: false,
    btnLoading: true,
    show: false,

    editorText: "Tulis Berita Anda Disini ...",
    editorOptions: {
      hideModeSwitch: true,
    },

    refUserlvl: [],

    berita_judul: "",
    berita_thumbnail_ket: "",
    berita_isi: "",
    berita_deskripsi: "",
    berita_thumbnail: "",
    urlImage: "",
  }),

  methods: {
    async openModal() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.refUserlvl = await getRef.Userlvl();
      this.ModalAdd = true;
    },

    getHTML() {
      this.berita_isi = this.$refs.toastuiEditor.invoke("getHTML");
    },

    async add() {
      this.btnLoading = false;

      const data = new FormData();
      data.append("berita_judul", this.berita_judul);
      data.append("berita_thumbnail_ket", this.berita_thumbnail_ket);
      data.append("berita_isi", this.berita_isi);
      data.append("berita_deskripsi", this.berita_deskripsi);
      data.append("berita_thumbnail", this.berita_thumbnail);

      const url = process.env.VUE_APP_API_BASE + "berita";
      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.btnLoading = true;
          this.closeModal();
        });
    },

    onFile(value) {
      this.berita_thumbnail = value;
      this.urlImage = URL.createObjectURL(this.berita_thumbnail);
    },

    closeModal() {
      this.ModalAdd = false;
    },
  },
};
</script>
