<template>
  <v-dialog v-model="modalView">
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>Detail Berita</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="mx-7 mt-5">
          <v-row>
            <!-- Judul Berita -->
            <v-col cols="12" md="12">
              <v-img :src="urlImage" max-height="350"></v-img>
            </v-col>
          </v-row>

          <v-row>
            <!-- Judul Berita -->
            <v-col cols="12" md="12" class="mb-n8">
              <span class="subtitle-2">Judul Berita</span>
              <v-text-field
                dense
                flat
                outlined
                class="mt-2"
                v-model="viewItem.berita_judul"
                autocomplete="off"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- Deskripsi Thumbnail -->
            <v-col cols="12" md="12" class="mb-n8">
              <span class="subtitle-2">Deskripsi Thumbnail</span>
              <v-text-field
                dense
                flat
                outlined
                class="mt-2"
                v-model="viewItem.berita_thumbnail_ket"
                autocomplete="off"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- Deskripsi Berita -->
            <v-col cols="12" md="12" class="mb-n8">
              <span class="subtitle-2">Deskripsi Berita</span>
              <v-textarea
                dense
                flat
                outlined
                class="mt-2"
                v-model="viewItem.berita_deskripsi"
                autocomplete="off"
                readonly
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <!-- Isi Berita -->
            <v-col cols="12">
              <v-card
                outlined
                class="pa-5"
                v-html="viewItem.berita_isi"
              ></v-card>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";

import modalView from "@/store/informasi/berita/modalView";

export default {
  computed: {
    modalView: {
      get() {
        return modalView.state.modalView;
      },
      set(value) {
        modalView.commit("toggleModal", value);
      },
    },

    viewItem: {
      get() {
        return modalView.state.berita;
      },
      set(value) {
        console.log(value);
      },
    },
  },

  watch: {
    async modalView() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.urlImage =
        this.viewItem.berita_thumbnail === ""
          ? process.env.VUE_APP_API_BASE + "upload/beritaGambar/default.jpg"
          : process.env.VUE_APP_API_BASE +
            "upload/beritaGambar/" +
            this.viewItem.berita_thumbnail;
    },
  },

  created() {
    if (
      this.$vuetify.breakpoint.name == "xs" ||
      this.$vuetify.breakpoint.name == "sm"
    ) {
      this.CWidth = "100%";
    }
  },

  data: () => ({
    session: "",
    btnLoading: true,

    berita_thumbnail: "",
    urlImage: "",
  }),

  methods: {
    getHTML() {
      this.viewItem.berita_isi = this.$refs.toastuiEditor.invoke("getHTML");
    },

    closeModal() {
      this.modalView = false;
    },
  },
};
</script>
